<template>
    <div id="v-pills-interviews" role="tabpanel" aria-labelledby="v-pills-interviews-tab">
       <div class="col content">
          <h5><strong>Scheduled interviews</strong></h5>
          <div class="row" v-if="interviews.length">
            <div class="col-md-12 mb-2" v-for="interview in interviews" :key="interview.room_id">
              <div class="card" :class="checkDate(interview.interview_time_from)">
                <div class="card-body">
                  <p class="job_desgination">{{interview.job_designation}}</p>
                  <p><strong>Interviewer:</strong> {{interview.interviewer}}</p>
                  <div class="d-flex justify-content-between">
                    <p><strong>Date: </strong>{{ interview.interview_date}}</p>
                    <p><strong>Time: </strong>{{getTime(interview.interview_time_from)}} - {{getTime(interview.interview_time_to)}}</p>
                    <div>
                      <a v-if="checkDate(interview.interview_time_from) == 'active'" :href="interview.interview_link" class="btn active" style="text-decoration:none;">Join</a>
                      <span v-else class="btn" :class="checkDate(interview.interview_time_from)" v-b-tooltip.hover :title="getHelpText(interview.interview_time_from)">{{checkDate(interview.interview_time_from)}}</span>                    
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
       </div>
    </div>
</template>

<script>
import Actions from '../libraries/apiActions.js';
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import '../assets/hire.css';
export default {
  name: 'ScheduledInterviews',
  data(){
    return{
      action: new Actions(),
      interviews: [],
      length: 0,
      page:1,
      offset: 0,
      limit: 25,
      total: 0,
    }
  },
  beforeMount(){
    this.loadInterviews();
  },
  methods:{
    loadInterviews() {
      let token = this.$cookies.get("hire-seeker").token;
      this.action.scheduledInterviews(token).then((res) => {
          if(res.meta.success) {
              this.interviews = res.data;
              this.limit = res.meta.limit;
              this.offset = res.meta.offset;
              this.total = res.meta.total;
              this.length = Math.ceil(res.meta.total/res.meta.limit);
          }
      }).catch((err) => {
          console.log(err)
      });
    },
    getTime(ts) {
        var timestamp = ts * 1000;
        var date = new Date(timestamp);
        return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    },
    checkDate(ts) {
          let exp_ts = ts + 1800;
          let current_time = new Date().getTime()/1000
          if(current_time < ts) return 'upcoming';
          else if(ts < current_time && current_time< exp_ts) return 'active';
          else if(current_time > exp_ts) return 'expired';
      },
    getHelpText(ts) {
        let exp_ts = ts + 1800;
        let current_time = new Date().getTime()/1000
        if(current_time < ts) return 'Interview not started yet, retry at the scheduled time';
        else if(current_time > exp_ts) return 'Interview link has been expired';
    },
  }
}
</script>
<style>
  .btn.expired {
    font-size: 0.75rem;
    color: white;
    background-color: #7cafcf ;
    text-transform: capitalize;
    /* cursor: not-allowed !important; */
  }

  .btn.active, .btn.upcoming {
      color: white;
      font-size: 0.75rem;
      background-color: green;
      text-transform: capitalize;
  }
  .job_desgination {
    color:#0054ad;
    font-weight: bold;
  }
  .expired, .expired p{
    color: #8f8f8f;
  }
</style>
